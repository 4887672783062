import React from "react";

const Delta = () => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6074 6.09344C12.8836 6.87329 12.8836 8.7267 11.6074 9.50656L3.0429 14.7404C1.71019 15.5549 -6.22869e-06 14.5957 -6.22869e-06 13.0339V2.56611C-6.22869e-06 1.00426 1.71019 0.0451183 3.04289 0.85955L11.6074 6.09344Z"
      fill="white"
    />
  </svg>
);

export default Delta;
