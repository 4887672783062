import React, { useContext } from "react";
import { getTrueClasses } from "../../../helpers";
import { SimpleContext } from "../../../store/context/simple-context";
import Logo from "./Logo";
import TabSection from "./TabSection";
import "../index.scss";

const Form = ({ children }) => {
  const { withTabs, isSignUp } = useContext(SimpleContext);
  return (
    <div className="PgAuth-Body">
      {withTabs && (
        <>
          <Logo />
          <TabSection />
        </>
      )}
      <div
        className={getTrueClasses(
          "PgAuth-Form",
          isSignUp && "PgAuth-Form_message",
          withTabs && "PgAuth-Form_tab"
        )}
      >
        {!withTabs && <Logo />}
        {children}
      </div>
    </div>
  );
};

export default Form;
