import React from "react";

const ArrowLeft = () => (
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.67175 1.72L1.32509 6.06667C0.811752 6.58 0.811752 7.42 1.32509 7.93333L5.67175 12.28"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLeft;
