import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../helpers";

const Error = ({ children, secondLine, withBottom }) => {
  return (
    <div
      className={getTrueClasses(
        "PgAuth-Error",
        secondLine && "PgAuth-Error_secondLine",
        withBottom && "PgAuth-Error_withBottom"
      )}
    >
      {children}
    </div>
  );
};

Error.propTypes = {
  withBottom: PropTypes.bool,
};

export default Error;
