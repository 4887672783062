import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Delta from "./Delta";
import "./index.scss";

const ReviewWithVideo = (props) => {
  const {
    title,
    youtubeId,
    screen: { JPG_2X },
    sliderPause,
    isStopVideo,
  } = props;
  const [isShowVideo, setIsShowVideo] = useState(false);

  useEffect(() => {
    let timeout;
    if (isShowVideo) {
      sliderPause();
      timeout = setTimeout(() => {
        setIsShowVideo(false);
      }, 47000);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isShowVideo]);

  useEffect(() => {
    if (isShowVideo) {
      isStopVideo && setIsShowVideo(false);
    }
  }, [isStopVideo]);

  return (
    <div className="PgAuth-R-RWV">
      <p className="PgAuth-R-RWV__title">{title}</p>
      <div className="PgAuth-R-RWV__screen">
        {isShowVideo ? (
          <iframe
            id="player"
            src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&modestbranding=1&controls=0&showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="youtube"
          />
        ) : (
          <div
            className="PgAuth-R-RWV-screenshot"
            onClick={() => setIsShowVideo(true)}
          >
            <div className="PgAuth-R-RWV-screenshot__play">
              <Delta />
            </div>
            <img src={JPG_2X} alt="" loading="lazy" />
          </div>
        )}
      </div>
      <p className="PgAuth-R-RWV__text">
        Right-click to see reviews from other members of the AMZScout community
        on Trustpilot
      </p>
    </div>
  );
};
ReviewWithVideo.propTypes = {
  title: PropTypes.string.isRequired,
  screen: PropTypes.object.isRequired,
  youtubeId: PropTypes.string.isRequired,
  sliderPlay: PropTypes.func.isRequired,
  sliderPause: PropTypes.func.isRequired,
  isStopVideo: PropTypes.bool.isRequired,
};
export default ReviewWithVideo;
