import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";

class AnalyticsController {
  constructor() {
    this.software =
      new URL(window.location.href).searchParams.get("software") || "LANDING";
    this.sendToAnalyticsEvent = this.sendToAnalyticsEvent.bind(this);
  }

  sendToAnalyticsEvent(authStates, action, label, sendToGA) {
    const category = `${this.software}|${authStates}`;
    this.send(category, action, label, sendToGA);
  }

  send(category, action, label, sendToGa) {
    sendToGa &&
      trackGa(action, {
        category,
        label: label,
        transport_type: "beacon",
      });
    track(category, action, label, null, this.software);
  }
}

export default AnalyticsController;
