import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SimpleContext } from "../../../store/context/simple-context";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Progress = ({ onSuccess }) => {
  const { translate: t, isCn, software, isSignUp } = useContext(SimpleContext);
  const [seconds, setSeconds] = useState(2);

  const calculateTimeLeft = useCallback(() => {
    setSeconds((prevState) => prevState > 0 && --prevState);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        calculateTimeLeft();
      } else {
        onSuccess();
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  const getTextBySoftware = (software) => {
    switch (software) {
      case "SCOUT_EXT_PRO":
        return " PRO";
      case "SCOUT_EXT_PRO_NEW":
        return " PRO AI";
      default:
        return "";
    }
  };

  return (
    <div
      className={getTrueClasses(
        "PgAuth-Progress",
        !isSignUp && "PgAuth-Progress_small"
      )}
    >
      {isSignUp && (
        <p className="PgAuth-Progress__title">
          {t("We’ve sent the password to your email address")}
        </p>
      )}
      <div
        className={getTrueClasses(
          "PgAuth-Progress-progress",
          !isSignUp && "PgAuth-Progress-progress_small"
        )}
      >
        <div className="PgAuth-Progress-progress__line" />
      </div>
      <p className="PgAuth-Progress__title">
        {isCn ? seconds : ""}{" "}
        {t(
          `You will be redirected to AMZScout${getTextBySoftware(software)} in`
        )}{" "}
        {!isCn ? seconds : ""} {t("seconds")}
      </p>
    </div>
  );
};

Progress.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default Progress;
