import React, { useContext } from "react";
import Header from "../Header";
import "./index.scss";
import Field from "../Field";
import PropTypes from "prop-types";
import Button from "../Button";
import Error from "../Error";
import OptionButton from "../OptionButton";
import { SimpleContext } from "../../../store/context/simple-context";
import { getTrueClasses } from "../../../helpers";
import ErrorBlock from "./ErrorBlock";

const Restore = ({
  handlePasswordRemember,
  handleSubmit,
  onPasswordSubmit,
}) => {
  const {
    translate: t,
    auth,
    passwordMode,
    updateAuthState,
  } = useContext(SimpleContext);
  const AUTHORIZATION_RESTORE_CONTENT = {
    incorrectEmail: t("Please enter the correct email address"),
    undefinedEmail: t(
      "Sorry, there is no user registered with this email address"
    ),
    passwordLongError: t("Your password should contain at least 6 characters "),
    passwordMatchError: [
      t("The passwords don’t match"),
      t("Please enter the same password twice"),
    ],
    passwordInvalidCharacter: [
      t("Please use only lowercase and"),
      t("uppercase latin letters and numbers"),
    ],
  };
  const error = auth.error ? AUTHORIZATION_RESTORE_CONTENT[auth.error] : false;

  return (
    <div className="PgAuth-Restore">
      {!passwordMode ? (
        <>
          <Header title={t("Forgot password")} />
          <p className="PgAuth-Restore__description">
            {t(
              "Please enter your email address in order to reset your password"
            )}
          </p>
          <form className="PgAuth-Restore-form">
            <Field
              type="text"
              placeHolder="example@gmail.com"
              label={`${t("Your email")}:`}
              alert={error}
              currentValue={auth.email}
              handleChange={(e) => updateAuthState({ email: e.target.value })}
              handleSuccess={handleSubmit}
            />
            {error ? <Error>{error}</Error> : null}
            <Button
              customClass={getTrueClasses(
                "PgAuth-Restore-form__btn",
                !auth.btnIsActive && "PgAuth-Button_disabled"
              )}
              handleClick={handleSubmit}
            >
              {t("Continue")}
            </Button>
          </form>
          <OptionButton
            onClick={handlePasswordRemember}
            customClass="PgAuth-Restore__option"
          >
            {t("I remember my password")}
          </OptionButton>
        </>
      ) : (
        <>
          <p className="PgAuth-Restore__description PgAuth-Restore__description_bg">
            {t("Please set up a new password for your account")}
          </p>
          <form className="PgAuth-Restore-form">
            <Field
              handleChange={(e) =>
                updateAuthState({ password: e.target.value })
              }
              currentValue={auth.password}
              label={t("New password:")}
              placeHolder="******************"
              type="password"
              alert={error}
              handleSuccess={onPasswordSubmit}
              compact
            />
            <Field
              handleChange={(e) =>
                updateAuthState({ confirmationPassword: e.target.value })
              }
              currentValue={auth.confirmationPassword}
              label={t("Repeat new password:")}
              placeHolder="******************"
              type="password"
              alert={error}
              handleSuccess={onPasswordSubmit}
              compact
            />
            <ErrorBlock error={error} />
            <Button
              handleClick={onPasswordSubmit}
              customClass={getTrueClasses(
                "PgAuth-Restore-form__btn PgAuth-Restore-form__btn_bg",
                !auth.btnIsActive && "PgAuth-Button_disabled"
              )}
            >
              {t("Save password")}
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

Restore.propTypes = {
  handlePasswordRemember: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onPasswordSubmit: PropTypes.func.isRequired,
};

export default Restore;
