export const ANALYTICS_PARAMS = {
  AUTH_STATES: {
    SIGN_UP_FORM: "SignUpForm",
    SIGN_IN_FORM: "SignInForm",
    FORGOT_PASSWORD: "ForgotPassword",
    NEW_PASSWORD: "NewPassword",
    SOCIAL_FORM: "SocialForm",
  },
  ACTIONS: {
    ERROR_AT_LEAST_6_CHARACTERS: "error_at_least_6_characters",
    ERROR_INCORRECT_EMAIL: "error_incorrect_email",
    ERROR_NOT_FOUND: "error_not_found",
    ERROR_OUTDATED_LINK: "error_outdated_link",
    ERROR_PASSWORDS_DONT_MATCH: "error_passwords_dont_match",
    ERROR_REGISTERED_EMAIL: "error_registered_email",
    ERROR_UNDEFINED: "error_undefined",
    NEW_EMAIL_EVENT: "new_email_event",
    SHOW: "show",
    SUCCESS: "success",
  },
  LABEL: {
    EMAIL: "email",
    EMPTY: "",
  },
};
export const RESET_AUTH_STATE = {
  email: "",
  error: "",
  password: "",
  confirmationPassword: "",
  passwordFieldType: "password",
  btnIsActive: true,
};