import React, { useContext } from "react";
import { SimpleContext } from "../../../store/context/simple-context";
import PropTypes from "prop-types";
import "./index.scss";

const ForgotView = ({ clearAndHandle }) => {
  const { translate: t, isCn } = useContext(SimpleContext);

  return (
    <>
      <p className="PgAuth-Message__title">
        {t(
          "Please check your email. We have sent a link that you can follow to reset your password"
        )}
      </p>
      <p className="PgAuth-Message__or">{t("or")}</p>
      <div className="PgAuth-Message__description">
        {!isCn && (
          <button
            className="PgAuth-Message__button"
            onClick={() => clearAndHandle(false)}
          >
            {t("Sign in")}
          </button>
        )}{" "}
        {t("with current password")}{" "}
        {isCn && (
          <button
            className="PgAuth-Message__button"
            onClick={() => clearAndHandle(false)}
          >
            {t("Sign in")}
          </button>
        )}
      </div>
    </>
  );
};

ForgotView.propTypes = {
  clearAndHandle: PropTypes.func.isRequired,
};

export default ForgotView;
