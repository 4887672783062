import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Button = ({ customClass, children, handleClick }) => {
  return (
    <button
      className={getTrueClasses(customClass, "PgAuth-Button")}
      type="submit"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  customClass: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired,
  ]),
  handleClick: PropTypes.func.isRequired,
};

export default Button;
