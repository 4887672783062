import React from "react";
import PropTypes from "prop-types";
import Error from "../Error";

const ErrorBlock = ({ error }) => {
  return error ? (
    typeof error === "object" ? (
      error.map((err, i) => (
        <Error secondLine={i >= 1} key={i}>
          {err}
        </Error>
      ))
    ) : (
      <Error>{error}</Error>
    )
  ) : null;
};

ErrorBlock.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
};

export default ErrorBlock;
