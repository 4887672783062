import React, { useContext } from "react";
import { SimpleContext } from "../../../store/context/simple-context";
import "./index.scss";
import PropTypes from "prop-types";
import Field from "../Field";
import OptionButton from "../OptionButton";
import Button from "../Button";
import ErrorBlock from "./ErrorBlock";

const Sign = ({
  onSubmit,
  handleBtnClick,
  onShowPassword,
  handleForgottenSwitch,
}) => {
  const {
    translate: t,
    isSignUp,
    auth,
    updateAuthState,
  } = useContext(SimpleContext);
  const AUTHORIZATION_SIGN_CONTENT = {
    incorrectEmail: t("Please enter the correct email address"),
    alreadyUseEmail: [
      t(
        "This email address is already registered. Please use another email address to sign up, or use this email address to"
      ),
      t("sign in"),
    ],
    invalidPassword: t("Please enter the correct email address and password"),
  };
  const error = auth.error ? AUTHORIZATION_SIGN_CONTENT[auth.error] : false;
  return (
    <div className="PgAuth-Sign">
      <form className="PgAuth-Sign-form">
        <Field
          type="text"
          placeHolder="example@gmail.com"
          label={`${t("Your email")}:`}
          alert={error}
          currentValue={auth.email}
          handleChange={(e) => updateAuthState({ email: e.target.value })}
          handleSuccess={onSubmit}
        />
        {!isSignUp ? (
          <Field
            label={`${t("Your password")}:`}
            placeHolder="******************"
            type={auth.passwordFieldType}
            alert={error}
            currentValue={auth.password}
            handleView={onShowPassword}
            handleChange={(e) => updateAuthState({ password: e.target.value })}
            handleSuccess={onSubmit}
          >
            <ErrorBlock error={error} handleBtnClick={handleBtnClick} />
            <OptionButton statusError={error} onClick={handleForgottenSwitch}>
              {t("I forgot my password")}
            </OptionButton>
          </Field>
        ) : (
          <ErrorBlock error={error} handleBtnClick={handleBtnClick} />
        )}
        <Button
          customClass={
            auth.btnIsActive
              ? "PgAuth-Sign-form__btn"
              : "PgAuth-Sign-form__btn PgAuth-Button_disabled"
          }
          handleClick={onSubmit}
        >
          {t("Continue")}
        </Button>
        {isSignUp && (
          <div className="PgAuth-Sign-form__switch">
            {t("If you already have an account, please ")}
            <button className="clearBtn" onClick={handleBtnClick}>
              {t("Sign in")}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

Sign.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleBtnClick: PropTypes.func.isRequired,
  onShowPassword: PropTypes.func.isRequired,
  handleForgottenSwitch: PropTypes.func.isRequired,
};

export default Sign;
