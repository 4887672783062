import React, { useContext, useEffect } from "react";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import { SimpleContext } from "../../../store/context/simple-context";
import RequestController from "../../../controllers/authorization/requestController";
import "./index.scss";

const Loader = () => {
  const { hash, updateFormState, updateMessageDisplay } =
    useContext(SimpleContext);
  useEffect(() => {
    const requestController = new RequestController();

    requestController
      .checkRequest(hash)
      .then(() => {
        updateFormState({
          passwordMode: true,
          restoreState: true,
          loaderMode: false,
        });
      })
      .catch(() => {
        updateFormState({ loaderMode: false });
        updateMessageDisplay({
          display: true,
          status: true,
          error: true,
        });
      });
  }, []);

  return (
    <div className="PgAuth-Loader">
      <img className="PgAuth-Loader__img" src={GIFLoader} alt="loader" />
    </div>
  );
};

export default Loader;
