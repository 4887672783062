import React, { useCallback, useContext } from "react";
import { RESET_AUTH_STATE } from "../index.content";
import { SimpleContext } from "../../../store/context/simple-context";
import StatusView from "./StatusView";
import ForgotView from "./ForgotView";
import "./index.scss";

const Message = () => {
  const { updateFormState, updateMessageDisplay, message, updateAuthState } =
    useContext(SimpleContext);

  const clearAndHandle = useCallback((status) => {
    updateAuthState(RESET_AUTH_STATE);
    updateMessageDisplay({ display: false });
    updateFormState({
      restoreState: status,
      passwordMode: false,
      isSignUp: false,
    });
  }, []);

  return (
    <div className="PgAuth-Message">
      {message.status ? (
        <StatusView error={message.error} clearAndHandle={clearAndHandle} />
      ) : (
        <ForgotView clearAndHandle={clearAndHandle} />
      )}
    </div>
  );
};

export default Message;
