import React, { useContext } from "react";
import { SimpleContext } from "../../../store/context/simple-context";
import PropTypes from "prop-types";
import "./index.scss";

const StatusView = ({ error, clearAndHandle }) => {
  const { translate: t, isCn } = useContext(SimpleContext);

  return (
    <>
      <p className="PgAuth-Message__title">
        {error
          ? t("This link for changing a password is outdated or not found.")
          : t("You have successfully changed your password to AMZScout.")}
      </p>
      <div className="PgAuth-Message__description">
        {error ? (
          <>
            {t("Please")}{" "}
            <button
              className="PgAuth-Message__button"
              onClick={() => clearAndHandle(true)}
            >
              {t("try again")}
            </button>
          </>
        ) : (
          <>
            {t("Please")}
            {!isCn && " "}
            {!isCn && (
              <button
                className="PgAuth-Message__button"
                onClick={() => clearAndHandle(false)}
              >
                {t("sign in")}
              </button>
            )}
            {!isCn && " "}
            {t("with your new password")}
            {isCn && (
              <button
                className="PgAuth-Message__button"
                onClick={() => clearAndHandle(false)}
              >
                {t("sign in")}
              </button>
            )}
            .
          </>
        )}
      </div>
    </>
  );
};

StatusView.propTypes = {
  error: PropTypes.bool,
  clearAndHandle: PropTypes.func.isRequired,
};

export default StatusView;
