import React, { useContext } from "react";
import { SimpleContext } from "../../../store/context/simple-context";
import PropTypes from "prop-types";
import Error from "../Error";

const ErrorBlock = ({ error, handleBtnClick }) => {
  const { isSignUp } = useContext(SimpleContext);
  return error ? (
    <Error withBottom={!isSignUp}>
      {typeof error === "object" ? (
        <>
          {error[0]}{" "}
          <button onClick={handleBtnClick} className="clearBtn">
            {error[1]}
          </button>
          .
        </>
      ) : (
        error
      )}
    </Error>
  ) : null;
};

ErrorBlock.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  handleBtnClick: PropTypes.func.isRequired,
};

export default ErrorBlock;
