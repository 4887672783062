import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";

const OptionButton = ({ children, statusError, customClass, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={getTrueClasses(
        "PgAuth-OptionButton clearBtn",
        statusError && "PgAuth-OptionButton_alert",
        customClass
      )}
    >
      {children}
    </button>
  );
};

OptionButton.propTypes = {
  statusError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  customClass: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default OptionButton;
