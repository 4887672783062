import React, { useCallback, useContext } from "react";
import { SimpleContext } from "../../store/context/simple-context";
import {
  ANALYTICS_PARAMS,
  RESET_AUTH_STATE,
} from "../../components/authorization/index.content";
import RequestController from "../../controllers/authorization/requestController";
import PropTypes from "prop-types";
import { checkEmail } from "../../helpers/";
import Sign from "../../components/authorization/Sign";

const SignContainer = ({ onSuccess }) => {
  const {
    sendToAnalyticsEvent,
    isSignUp,
    updateFormState,
    updateAuthState,
    auth,
  } = useContext(SimpleContext);

  const requestController = new RequestController();
  const onShowPassword = (e) => {
    if (
      e.target.tagName === "IMG" ||
      e.target.id === "authShowPasswordButton"
    ) {
      e.preventDefault();
      updateAuthState({
        passwordFieldType:
          auth.passwordFieldType === "password" ? "text" : "password",
      });
    }
  };
  const handleBtnClick = useCallback(() => {
    updateAuthState(RESET_AUTH_STATE);
    updateFormState({ isSignUp: !isSignUp });
  }, [isSignUp]);

  const handleForgottenSwitch = useCallback(() => {
    updateAuthState(RESET_AUTH_STATE);
    updateFormState({ restoreState: true });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    updateAuthState({ btnIsActive: false });
    if (isSignUp) {
      handleSignUpSubmit(auth.email);
    } else {
      handleSignInSubmit(auth.email, auth.password);
    }
  };

  const sendSuccessEvent = useCallback((authStates) => {
    sendToAnalyticsEvent(
      authStates,
      ANALYTICS_PARAMS.ACTIONS.SUCCESS,
      ANALYTICS_PARAMS.LABEL.EMAIL,
      true
    );
  }, []);

  const sendErrorEvent = useCallback((authStates) => {
    sendToAnalyticsEvent(
      authStates,
      ANALYTICS_PARAMS.ACTIONS.ERROR_INCORRECT_EMAIL,
      ANALYTICS_PARAMS.LABEL.EMAIL,
      true
    );
  }, []);

  const sendEmailUsedEvent = useCallback(() => {
    sendToAnalyticsEvent(
      ANALYTICS_PARAMS.AUTH_STATES.SIGN_UP_FORM,
      ANALYTICS_PARAMS.ACTIONS.ERROR_REGISTERED_EMAIL,
      ANALYTICS_PARAMS.LABEL.EMAIL,
      true
    );
  }, []);

  const handleSignInSubmit = useCallback(
    (email, password) => {
      if (checkEmail(email) && password.trim().length > 0) {
        updateAuthState({ error: "" });
        requestController
          .loginRequest(email, password)
          .then((data) => {
            sendSuccessEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_IN_FORM);
            onSuccess(data);
          })
          .catch((err) => {
            sendErrorEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_IN_FORM);
            updateAuthState({ error: "invalidPassword", btnIsActive: true });
          });
      } else {
        sendErrorEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_IN_FORM);
        updateAuthState({ error: "invalidPassword", btnIsActive: true });
      }
    },
    [requestController]
  );

  const handleSignUpSubmit = useCallback(
    (email) => {
      if (checkEmail(email)) {
        updateAuthState({ error: "" });
        requestController
          .registerRequest(email, "LANDING")
          .then((parsedData) => {
            if (!parsedData) {
              sendEmailUsedEvent();
              updateAuthState({ error: "alreadyUseEmail", btnIsActive: true });
            } else {
              sendSuccessEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_UP_FORM);
              onSuccess(parsedData);
            }
          })
          .catch(() => {
            sendErrorEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_UP_FORM);
            updateAuthState({ error: "incorrectEmail", btnIsActive: true });
          });
      } else {
        sendErrorEvent(ANALYTICS_PARAMS.AUTH_STATES.SIGN_UP_FORM);
        updateAuthState({ error: "incorrectEmail", btnIsActive: true });
      }
    },
    [requestController]
  );

  return (
    <Sign
      onShowPassword={onShowPassword}
      handleBtnClick={handleBtnClick}
      onSubmit={onSubmit}
      handleForgottenSwitch={handleForgottenSwitch}
    />
  );
};

SignContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default SignContainer;
