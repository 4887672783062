import React, { useContext } from "react";
import PropTypes from "prop-types";
import SVG_INPUT_SHOW from "../../../assets/images/vector/pages/authorization/inputShowIcon.svg";
import { SimpleContext } from "../../../store/context/simple-context";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Field = ({
  type,
  label,
  placeHolder,
  children,
  alert,
  compact,
  currentValue,
  handleChange,
  handleView,
  handleSuccess,
}) => {
  const { translate: t } = useContext(SimpleContext);

  const handleEnter = (event) => {
    if (event.charCode === 13) {
      handleSuccess(event);
    }
  };

  return (
    <>
      <div className="PgAuth-Field-field">
        <label
          className={getTrueClasses(
            "PgAuth-Field-field__label",
            compact && "PgAuth-Field-field__label_compact"
          )}
        >
          {label}
        </label>
        <input
          className={getTrueClasses(
            "PgAuth-Field-field__input",
            compact && "PgAuth-Field-field__input_compact",
            alert && "PgAuth-Field-field__input_alert"
          )}
          type={type}
          placeholder={placeHolder}
          autoComplete="on"
          value={currentValue}
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        {handleView ? (
          <button
            id="authShowPasswordButton"
            className={getTrueClasses(
              "PgAuth-Field-field__showBtn",
              type === "text" && "PgAuth-Field-field__showBtn_open",
              "clearBtn"
            )}
            onClick={handleView}
          >
            <img src={SVG_INPUT_SHOW} alt={t("Show Password")} />
          </button>
        ) : null}
      </div>
      {children}
    </>
  );
};
Field.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  children: PropTypes.array,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  compact: PropTypes.bool,
  currentValue: PropTypes.string,
  handleChange: PropTypes.func,
  handleView: PropTypes.func,
  handleSuccess: PropTypes.func.isRequired,
};

export default Field;
