import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import { SLIDES } from "./index.content";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ReviewWithText from "./ReviewWithText";
import ReviewWithVideo from "./ReviewWithVideo";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./index.scss";

const Arrow = ({ className, style, onClick, arrowDirection }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    {arrowDirection === "right" ? <ArrowLeft /> : null}
    {arrowDirection === "left" ? <ArrowRight /> : null}
  </div>
);

const SETTINGS = {
  className: "PgAuth-R-slider",
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 20000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow arrowDirection="left" />,
  prevArrow: <Arrow arrowDirection="right" />,
};

const Reviews = () => {
  const [isReviewWithText, setIsReviewWithText] = useState(false);
  const sliderRef = useRef(null);

  const sliderPlay = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };
  const sliderPause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };
  useEffect(() => {
    isReviewWithText ? sliderPlay() : sliderPause();
  }, [isReviewWithText]);

  const slides = useMemo(
    () =>
      SLIDES.map(({ type, ...data }, i) =>
        type === "text" ? (
          <ReviewWithText key={i} {...data} />
        ) : (
          <ReviewWithVideo
            key={i}
            {...data}
            sliderPlay={sliderPlay}
            sliderPause={sliderPause}
            isStopVideo={isReviewWithText}
          />
        )
      ),
    [isReviewWithText]
  );

  return (
    <aside className="PgAuth-R">
      <Slider
        ref={sliderRef}
        autoplay={isReviewWithText}
        beforeChange={(current, next) => {
          setIsReviewWithText(next !== 0);
        }}
        {...SETTINGS}
      >
        {slides}
      </Slider>
    </aside>
  );
};

export default Reviews;
