// import JPC_ADVERTISING from "../../../assets/images/raster/pages/authorization/advertising_2.jpg";
import JPC_2X_ADVERTISING from "../../../assets/images/raster/pages/authorization/advertising_2@2x.jpg";


const AD = {
  type: "video",
  screen: {
    // JPG: JPC_ADVERTISING,
    JPG_2X: JPC_2X_ADVERTISING,
  },
  youtubeId: "FDZVkb7leQU",
  title: "Join 250,000+ Successful Sellers Who Have Started or Upgraded Their Own Amazon Businesses with AMZScout",
};
const JUAN = {
  type: "text",
  title: "A Must-Have for Amazon Sellers!",
  text: "AMZScout has been a game-changer for my Amazon business. This tool is indispensable for product research, providing accurate data, trend analysis, and low-competition sales opportunities. The Chrome extension is a time-saver, and their customer support is exceptional. AMZScout has more than paid for itself, and I highly recommend it to any serious Amazon seller. Thanks AMZScout for helping me succeed!",
  author: "Juan Pablo Hinojosa, US",
};
const AKUA = {
  type: "text",
  title: "Very Helpful Sales Tool",
  text: "I just opened an Amazon Seller account and AMZScout was incredibly helpful as I started my journey. It really enabled me to learn about all the products I could sell and should not sell. Even the onboarding process was helpful!",
  author: "Akua Agadzi, CA",
};
const JESSE = {
  type: "text",
  title: "Transparent Company and Very Insightful Tool",
  text: "I recently started looking into my options for selling with FBA. AMZScout was the most common name I’d heard of, so I started to do some research. Everything looked accurate, and I am confident that the insights they provide have helped me determine which products would be most profitable. This tool has been easy to use, and the filters did everything I set them to do. I found a few products and am continuing my research, but have decided not to continue my subscription because I am not currently selling. When the renewal date was approaching, AMZScout emailed me to let me know instead of just automatically charging me for the next month. This company is one of the most transparent I have come across when it comes to billing - no tricks or cash-grabbing tactics.",
  author: "Jesse Westbrook, US",
};
const ANTHONY_REYES = {
  type: "text",
  title: "Reliable and easy to use",
  text: "AMZScout is my go-to for finding the best selling products and info. Alex from the support team answered my questions respectfully and addressed my concerns efficiently. I’d definitely recommend AMZScout to future Amazon entrepreneurs!",
  author: "Anthony Reyes, US",
};
const CEES = {
  type: "text",
  title: "Just the right tools you need for the…",
  text: "Just the right tools you need for the right price. I like the feel and look of the platform, which is easy to use. The Niche Indicator gives you deep insights if the niche is worthwhile to research more deeply, and the profit calculator is very useful for doing proper product research. The technical support provided by a real person is also very helpful and effective in resolving any issues or concerns you might have.",
  author: "Cees Steenkuyl, ES",
};
const KESHIA = {
  type: "text",
  title: "I started selling online through Amazon…",
  text: "I started selling online through Amazon and Amazon Scout has made the process so easy and efficient, especially for a new seller who’s still learning the ropes on how to be a successful seller with Amazon FBA. I would suggest that all sellers use this platform. It’s one of the best apps to help you grow and streamline your business.",
  author: "Keshia Glover, US",
};
const CEYLAN = {
  type: "text",
  title: "I tested AMZScout and it has really helped me…",
  text: "I tested AMZScout and it has really helped me do analyses for my Amazon business. Their tools are so easy to use. Whenever I had any questions, the team answered me promptly.",
  author: "Ceylan Cig Koz, TR",
};
const SHAZIA = {
  type: "text",
  title: "Great online tool",
  text: "I am new to Amazon and looking for different tools to help me understand how to sell on this platform. I found AMZScout quite helpful and easy to understand for product search. I would definitely recommend this for online Amazon sales.",
  author: "Shazia Ashfaq rana, GB",
};
const ANTHONY = {
  type: "text",
  title: "This Company has been providing great…",
  text: "This Company has been providing great software for several years now. I have continued to renew my subscription because of their great customer service, along with their of the PRO Extension and Database functions.",
  author: "Anthony, US",
};
const LOVELESH = {
  type: "text",
  title: "Best tool for starting your Amazon business",
  text: "I am a beginner at selling on Amazon and I came across AMZScout a month ago. This tool provided me with the right balance of features and price. I bought the monthly subscription plan, and have been using it since then to check if products are feasible for selling. I like the fact that you get unrestricted access, even with the most basic plan, and you don't have to shell out more money on a subscription to access certain features. I would recommend this to any beginner who’s looking for a tool to help them with their business.",
  author: "Lovelesh Patel, CA",
};
const MICHAEL = {
  type: "text",
  title: "Impressive Product - Even More Impressive Support",
  text: [
    "After reviewing several options for Amazon Product research applications, I ended up choosing AMZScout.",
    "I was impressed with their ease of use and the way data is presented. Their Chrome Extension made product searches very easy.",
    "Even more impressive was the level of support provided whenever I had a question or an issue. Responses to my messages were quick and the overall customer experience was great.",
    "I would highly recommend AMZScout to anyone in need of an Amazon Research tool."
  ],
  author: "Michael Mehterian, US",
};
const TEMKA = {
  type: "text",
  title: "AMZScout has become my secret weapon on…",
  text: "AMZScout has become my secret weapon on the Amazon marketplace. It simplifies product research, offers precise sales estimates, and gives me a competitive edge. The Chrome extension is a lifesaver.",
  author: "Temka Sydyk, KG",
};
const DEBBIE = {
  type: "text",
  title: "Does everything it says on the package!",
  text: "The fact that whenever you have a question, it gets answered quickly is invaluable when you’re learning something new. I find the software does everything I need it to do, and more. Cannot fault it. I did the trial version to find my way, and now I am about to purchase the full version. Great price too!",
  author: "Debbie Collins, GB",
};
const MICHAEL_KAN = {
  type: "text",
  title: "Amazon Product Finder is a great tool…",
  text: [
    "Amazon Product Finder is a great tool for anyone who wants to start or grow their online business on Amazon. It allows you to search for products across different categories, niches, and markets. It gives you detailed information about each product, such as price, sales, reviews, ratings, competition, and more. You can also filter and sort the results by various criteria, such as demand, profitability, seasonality, and opportunity score.",
    "I have been using the Amazon Product Finder for a few months now and I am very satisfied with the results. It has helped me discover many profitable products that I would not have found otherwise. It has also saved me a lot of time and effort in doing my own market research and analysis. I can easily compare different products and see their strengths and weaknesses. I can also track the performance of my products over time, and I get alerts when there are any changes in the market."
  ],
  author: "Michael Kan, MY",
};
const KATE = {
  type: "text",
  title: "Very helpful tool - highly recommend!",
  text: "Very helpful tool! AMZScout has everything you need to perform effective product and market research for Amazon products. I found the tools to be really easy to use and laid out clearly. I also love that they have links to suppliers on Alibaba. Their customer service is also fantastic and very quick to respond. All of the extra learning resources are invaluable as well. I highly recommend these tools to any new or seasoned Amazon sellers!",
  author: "Kate Brown, GB",
};

export const SLIDES = [
  AD,
  JUAN,
  AKUA,
    // JESSE,
  ANTHONY_REYES,
  CEES,
  KESHIA,
  CEYLAN,
  SHAZIA,
  ANTHONY,
  // LOVELESH,
  // MICHAEL,
  TEMKA,
  DEBBIE,
 // MICHAEL_KAN,
  KATE
];

