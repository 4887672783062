import React, { useContext } from "react";
import { SimpleContext } from "../../../store/context/simple-context";
import SVG_LOGO_BLACK from "../../../assets/images/vector/components/common/logo_amzscout_black.svg";
import { getTrueClasses } from "../../../helpers";

const Logo = () => {
  const { withTabs } = useContext(SimpleContext);
  return (
    <img
      className={getTrueClasses(
        "PgAuth-Form__logo",
        withTabs && "PgAuth-Form__logo_ext"
      )}
      src={SVG_LOGO_BLACK}
      alt="logo"
      width="90"
      height="35"
    />
  );
};

export default Logo;
