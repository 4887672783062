import React, { useCallback, useContext } from "react";
import { SimpleContext } from "../../../../store/context/simple-context";
import { RESET_AUTH_STATE } from "../../index.content";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const TabSection = () => {
  const {
    translate: t,
    updateFormState,
    updateAuthState,
    isSignUp,
  } = useContext(SimpleContext);

  const switchForm = useCallback((status) => {
    updateAuthState(RESET_AUTH_STATE);
    updateFormState({ isSignUp: status });
  }, []);

  return (
    <div className="PgAuth-TabSection">
      <button
        className={getTrueClasses(
          "PgAuth-TabSection__button",
          isSignUp && "PgAuth-TabSection__button_selected"
        )}
        onClick={() => switchForm(true)}
      >
        {t("Sign up")}
      </button>
      <button
        className={getTrueClasses(
          "PgAuth-TabSection__button",
          !isSignUp && "PgAuth-TabSection__button_selected"
        )}
        onClick={() => switchForm(false)}
      >
        {t("Sign in")}
      </button>
    </div>
  );
};

export default TabSection;
