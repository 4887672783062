import React from "react";

const ArrowRight = () => (
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.940125 12.28L5.28679 7.93333C5.80013 7.42 5.80013 6.58 5.28679 6.06667L0.940125 1.72"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRight;
