import React, { useContext } from "react";
import { SimpleContext } from "../../store/context/simple-context";
import { ANALYTICS_PARAMS } from "../../components/authorization/index.content";
import SocialModel from "../../models/authorization/socialModel";
import WeiboController from "../../controllers/authorization/weiboController";
import PropTypes from "prop-types";
import Social from "../../components/authorization/Social";

const SocialContainer = ({ onSuccess }) => {
  const { sendToAnalyticsEvent, isSignUp } = useContext(SimpleContext);

  const weiboController = new WeiboController();
  const socialModel = new SocialModel();

  const handleLogin = (data, provider) => {
    if (!data.accessToken) {
      handleFail(
        new Error(
          provider === "fb" ? "popup_closed_by_user" : "access_token_error"
        )
      );
      return;
    }
    socialModel
      .authorize(data, provider)
      .then((res) =>
        res.json().then((parsedRes) => {
          sendToAnalyticsEvent(
            ANALYTICS_PARAMS.AUTH_STATES.SIGN_IN_FORM,
            ANALYTICS_PARAMS.ACTIONS.SUCCESS,
            provider,
            true
          );
          onSuccess(parsedRes);
        })
      )
      .catch((err) => {
        handleFail(err);
      });
  };

  const handleWeiboLogin = () => {
    weiboController
      .authorize()
      .then((auth) => {
        return handleLogin(auth, auth.provider);
      })
      .catch((err) => {
        handleFail(err);
      });
  };

  const handleFail = (e) => {
    const action = e?.error || e?.message;
    sendToAnalyticsEvent(
      ANALYTICS_PARAMS.AUTH_STATES.SOCIAL_FORM,
      action || ANALYTICS_PARAMS.ACTIONS.ERROR_UNDEFINED,
      ANALYTICS_PARAMS.LABEL.EMPTY,
      true
    );
  };

  return (
    <Social
      logTitle={`Sign ${isSignUp ? "up" : "in"}`}
      onLogin={handleLogin}
      onSocialFail={handleFail}
      onWeibo={handleWeiboLogin}
    />
  );
};

SocialContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default SocialContainer;
