import React, { useEffect, useContext, useCallback } from "react";
import { SimpleContext } from "../../store/context/simple-context";
import { ANALYTICS_PARAMS } from "../../components/authorization/index.content";
import RequestController from "../../controllers/authorization/requestController";
import { checkEmail } from "../../helpers/";
import Restore from "../../components/authorization/Restore";

const RestoreContainer = () => {
  const {
    sendToAnalyticsEvent,
    updateFormState,
    updateMessageDisplay,
    hash,
    auth,
    updateAuthState,
  } = useContext(SimpleContext);

  useEffect(() => {
    updateAuthState({ error: "" });
  }, [auth.password, auth.confirmationPassword]);

  const handlePasswordRemember = useCallback(() => {
    updateAuthState({ error: "" });
    updateFormState({ restoreState: false });
  }, []);

  const sendToAnalyticsNewPasswordEvent = useCallback((action) => {
    sendToAnalyticsEvent(
      ANALYTICS_PARAMS.AUTH_STATES.NEW_PASSWORD,
      action,
      ANALYTICS_PARAMS.LABEL.EMPTY,
      true
    );
  }, []);

  const sendToAnalyticsForgotPasswordEvent = useCallback(
    (action, label = ANALYTICS_PARAMS.LABEL.EMPTY) => {
      sendToAnalyticsEvent(
        ANALYTICS_PARAMS.AUTH_STATES.FORGOT_PASSWORD,
        action,
        label,
        true
      );
    },
    []
  );

  const checkPasswordFields = () => {
    let status = false;
    if (auth.password !== auth.confirmationPassword) {
      sendToAnalyticsNewPasswordEvent(
        ANALYTICS_PARAMS.ACTIONS.ERROR_PASSWORDS_DONT_MATCH
      );
      updateAuthState({ error: "passwordMatchError" });
    } else if (auth.password.length < 6) {
      sendToAnalyticsNewPasswordEvent(
        ANALYTICS_PARAMS.ACTIONS.ERROR_AT_LEAST_6_CHARACTERS
      );
      updateAuthState({ error: "passwordLongError" });
    } else if (!/^[a-zA-Z0-9]+$/.test(auth.password)) {
      sendToAnalyticsNewPasswordEvent(
        ANALYTICS_PARAMS.ACTIONS.ERROR_AT_LEAST_6_CHARACTERS
      );
      updateAuthState({ error: "passwordInvalidCharacter" });
    } else {
      updateAuthState({ error: "" });
      status = true;
    }
    return status;
  };

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    if (checkPasswordFields() && hash) {
      updateAuthState({ btnIsActive: false });
      const requestController = new RequestController();
      requestController
        .saveRequest(
          encodeURIComponent(hash),
          encodeURIComponent(auth.confirmationPassword)
        )
        .then(() => {
          sendToAnalyticsNewPasswordEvent(ANALYTICS_PARAMS.ACTIONS.SUCCESS);
          updateAuthState({ btnIsActive: true });
          updateMessageDisplay({ display: true, error: false, status: true });
        })
        .catch(() => {
          updateAuthState({ btnIsActive: true });
          updateMessageDisplay({ display: true, error: true, status: true });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkEmail(auth.email)) {
      handleRestorePassword(auth.email);
    } else {
      sendToAnalyticsForgotPasswordEvent(
        ANALYTICS_PARAMS.ACTIONS.ERROR_INCORRECT_EMAIL,
        ANALYTICS_PARAMS.LABEL.EMAIL
      );
      updateAuthState({ error: "incorrectEmail" });
    }
  };

  const handleRestorePassword = useCallback((email) => {
    updateAuthState({ btnIsActive: false });
    const requestController = new RequestController();
    requestController
      .restoreRequest(email)
      .then(() => {
        sendToAnalyticsForgotPasswordEvent(ANALYTICS_PARAMS.ACTIONS.SUCCESS);
        updateMessageDisplay({ display: true, error: false, status: false });
      })
      .catch((err) => {
        if (err.message === "user.not.found") {
          sendToAnalyticsForgotPasswordEvent(
            ANALYTICS_PARAMS.ACTIONS.ERROR_NOT_FOUND
          );
          updateAuthState({ error: "undefinedEmail" });
        } else {
          sendToAnalyticsForgotPasswordEvent(
            ANALYTICS_PARAMS.ACTIONS.ERROR_INCORRECT_EMAIL,
            ANALYTICS_PARAMS.LABEL.EMAIL
          );
          updateAuthState({ error: "incorrectEmail" });
        }
        updateAuthState({ btnIsActive: true });
      });
  }, []);

  return (
    <Restore
      handlePasswordRemember={handlePasswordRemember}
      onPasswordSubmit={onPasswordSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default RestoreContainer;
