import React from "react";
import SVG_BEST from "../../../../assets/images/vector/pages/authorization/best.svg";
import SVG_FIVE_STARS from "../../../../assets/images/vector/pages/authorization/five_stars.svg";
import SVG_TRUSTPILOT_LOGO_BIG from "../../../../assets/images/vector/pages/authorization/trustpilot_logo_big.svg";
import SVG_TRUSTPILOT_LOGO from "../../../../assets/images/vector/pages/authorization/trustpilot_logo.svg";
import SVG_IC_GO from "../../../../assets/images/vector/pages/authorization/ic_go.svg";
import PropTypes from "prop-types";
import "./index.scss";

const ReviewWithText = ({ title, text, author }) => {
  // const trustPilotWidgetRef = React.useRef(null);
  // useEffect(() => {
  //   const checkTrustpilot = setInterval(() => {
  //     if (window?.Trustpilot) {
  //       window.Trustpilot.loadFromElement(trustPilotWidgetRef.current, true);
  //       clearInterval(checkTrustpilot);
  //     }
  //   }, 200);
  //
  //   return () => {
  //     clearInterval(checkTrustpilot);
  //     if (window?.Trustpilot?.remove) {
  //       window.Trustpilot.remove(trustPilotWidgetRef.current);
  //     }
  //   };
  // }, []);

  return (
    <div className="PgAuth-R-RWT">
      <div className="PgAuth-R-RWT-header">
        <img className="PgAuth-R-RWT__best" src={SVG_BEST} alt="" />
        <img
          className="PgAuth-R-RWT__tpLogoBig"
          src={SVG_TRUSTPILOT_LOGO_BIG}
          alt=""
        />
        <p className="PgAuth-R-RWT__excellent">
          <span>‘</span>
          Excellent
          <span>’</span>
        </p>
        <a
          href="https://www.trustpilot.com/review/amzscout.net"
          className="PgAuth-R-RWT-linkBlock"
          target="_blank"
        >
          <span>See our reviews on</span>
          <img
            className="PgAuth-R-RWT-linkBlock__tpLogo"
            src={SVG_TRUSTPILOT_LOGO}
            alt=""
          />
          <img
            className="PgAuth-R-RWT-linkBlock__icGo"
            src={SVG_IC_GO}
            alt=""
          />
        </a>
        {/* <div */}
        {/*   ref={trustPilotWidgetRef} */}
        {/*   className="PgAuth-R-RWT-TP" */}
        {/*   data-locale="en-US" */}
        {/*   data-template-id="5419b6a8b0d04a076446a9ad" */}
        {/*   data-businessunit-id="58a605050000ff00059cd0f6" */}
        {/*   data-style-height="20px" */}
        {/*   data-style-width="244px" */}
        {/*   data-theme="dark" */}
        {/* > */}
        {/*   <a */}
        {/*     className="PgAuth-R-RWT__link" */}
        {/*     href="https://www.trustpilot.com/review/amzscout.net" */}
        {/*     target="_blank" */}
        {/*     rel="noopener" */}
        {/*   > */}
        {/*     Trustpilot */}
        {/*   </a> */}
        {/* </div> */}
      </div>
      <p className="PgAuth-R-RWT__title">{title}</p>
      <img
        className="PgAuth-R-RWT__fiveStars"
        src={SVG_FIVE_STARS}
        alt=""
        loading="lazy"
      />
      <div className="PgAuth-R-RWT-text">
        {typeof text === "object" ? (
          text.map((p, i) => <p key={i}>{p}</p>)
        ) : (
          <p>{text}</p>
        )}
      </div>
      <p className="PgAuth-R-RWT__author">{author}</p>
    </div>
  );
};
ReviewWithText.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default ReviewWithText;
